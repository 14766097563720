


import { Button, Row } from 'antd';
import React, { FC, useState } from 'react'
import ConnectModal from './ConnectModal';


type IProps = {
  title: string,
  description: string
}

const HeaderInfo: FC<IProps> = (props: IProps) => {
  const { title, description } = props;

  return (
    <div
      style={{
        width: "100%",
        paddingTop: "30px",
        paddingBottom: "30px",
        paddingLeft: "10px"
      }}
    >
      <Row>
        <div
          style={{
            fontSize: "60px",
            fontFamily: "Jost-Bold",
            color: "#fff"
          }}
        >
          {title}
        </div>
      </Row>
      <Row>
        <div
          style={{
            fontSize: "18px",
            lineHeight: "20px",
            color: "#D1D1D1",
            maxWidth: "1000px"
          }}
        >
          <span
            style={{ whiteSpace: "pre-line", lineHeight: "26px" }}
            dangerouslySetInnerHTML={{
              __html: description
            }}
          />
        </div>
      </Row>
    </div>
  )
}
export default HeaderInfo;
