import React, { FC, useState } from 'react'

import "./index.less"
import BaseView from '~/components/BaseView';
import { Button, Card, Col, Image, Input, List, Row } from 'antd';
import StakePoolCard from "./StakePoolCard";
import { useListPool } from "./hooks/useListPool";
import HeaderInfo from '~/layouts/containers/HeaderInfo';
import { useWindowSize } from '~/hooks/useWindowSize';
import { useThemeStore } from "~/stores/themeStore";
import { SearchOutlined } from '@ant-design/icons';


const { Meta } = Card;

type IStakeNftViewProps = {
}

const pageSize = 10;

const StakeNftView: FC<IStakeNftViewProps> = (props: IStakeNftViewProps) => {


  const { width } = useWindowSize();
  const {
    data,
    isLoading,
    keySearch,
    setKeySearch,
  } = useListPool();
  const { themConfig } = useThemeStore();
  const { colorMode } = themConfig;


  return (
    <BaseView>

      <HeaderInfo
        title='Staking'
        description={`Stake Your Free Mint NFTs, Earn Your Incentives - <a href='https://mirror.xyz/0x2368dB14Cf7F0F7f975bE690687687fC01F6D31E/MVJ3e3_O5Z_ly-KHwao1RoXTa9SAmCHzvO7CWMQvWxk' target='_blank'>Read More Details</a>`}
      />

      <Row
        className='borderLinearInput'
        style={{
          maxWidth: width <= 500 ? "100%" : 420,
        }}>
        <Input
          style={{
            borderRadius: 24,
            height: 42,
            paddingLeft: 20,
            color: "#fff"
          }}
          placeholder='Search Name NFT or NFT Address'

          value={keySearch}
          onChange={(e) => {
            setKeySearch(e.target.value)
          }}
          prefix={<SearchOutlined />}
        />
      </Row>

      <Row
        style={{ width: "100%" }}
      >

        <List
          style={{ height: '100%', width: "100%" }}
          loading={isLoading}
          grid={{
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 3,
            xxl: 5,
          }}
          pagination={{
            align: 'end',
            position: 'bottom',
            pageSize,
          }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <StakePoolCard {...item} />
            </List.Item>
          )}
        />
      </Row>


      <div style={{ minHeight: "10vh", width: "100%" }} />

    </BaseView>
  )
}
export default StakeNftView;
