
import { FieldTimeOutlined, FireOutlined, GlobalOutlined, GroupOutlined, HomeOutlined, OrderedListOutlined, PartitionOutlined, PlusCircleOutlined, RiseOutlined } from '@ant-design/icons';
import { v4 } from 'uuid';
import { IRouter } from '~/routers';
import HomeView from './HomeView';
import CreateVaultView from './CreateVaultView';
import VaultDetailView from './VaultDetailView';
import InventoryViewV2 from './InventoryV2View';
import AffMintView from './AffMintView';
import FreeMintView from './FreeMintView';
import UserReferalView from './UserReferalView';
import QuestView from './QuestView';
import CommunityPoint from './CommunityPoint';
import StakeNftView from "./StakeNftView";
import LeaderBoardView from './LeaderBoardView';

export const mainRouter: IRouter[] = [
  // {
  //   path: "",
  //   key: v4(),
  //   element: <HomeView />,
  //   title: "Home",
  //   isMenu: true,
  //   icon: <HomeOutlined />,
  // },

  // {
  //   path: "create-vault",
  //   key: v4(),
  //   element: <CreateVaultView />,
  //   title: "Create Vault",
  //   isMenu: true,
  //   icon: <PlusCircleOutlined />,
  // },

  // {
  //   path: "vault-detail",
  //   key: v4(),
  //   element: <VaultDetailView />,
  //   title: "Vault Detail",
  //   isMenu: false,
  //   icon: <PlusCircleOutlined />,
  // },


  // {
  //   path: "aff-mint",
  //   key: v4(),
  //   element: <AffMintView />,
  //   title: "AFF Mint",
  //   isMenu: false,
  //   icon: <PlusCircleOutlined />,
  // },

  // {
  //   path: "free-mint",
  //   key: v4(),
  //   element: <FreeMintView />,
  //   title: "Free Mint",
  //   isMenu: true,
  //   icon: <FireOutlined />,
  // },

  // // {
  // //   key: v4(),
  // //   path: "quest",
  // //   element: <QuestView />,
  // //   isMenu: true,
  // //   icon: <FieldTimeOutlined />,
  // //   title: "Quest",
  // // },

  // {
  //   path: "inventory",
  //   key: v4(),
  //   element: <InventoryViewV2 />,
  //   title: "Inventory",
  //   isMenu: true,
  //   icon: <PartitionOutlined />,
  // },
  // {
  //   path: "my-referal",
  //   key: v4(),
  //   element: <UserReferalView />,
  //   title: "My referal",
  //   isMenu: true,
  //   icon: <OrderedListOutlined />,
  // },
  // {
  //   path: "community",
  //   key: v4(),
  //   element: <CommunityPoint />,
  //   title: "Community",
  //   isMenu: false,
  //   icon: <OrderedListOutlined />,
  // },
  // {
  //   path: "stake-nft",
  //   key: v4(),
  //   element: <StakeNftView />,
  //   title: "Stake NFT",
  //   isMenu: true,
  //   icon: <GroupOutlined />,
  // },

  {
    path: "",
    key: v4(),
    element: <StakeNftView />,
    title: "Stake NFT",
    isMenu: true,
    icon: <GroupOutlined />,
  },
  {
    path: "leaderboard",
    key: v4(),
    element: <LeaderBoardView />,
    title: "Leader Board",
    isMenu: false,
    icon: <RiseOutlined />,
  },
  {
    path: "free-mint",
    key: v4(),
    element: null,
    title: "Free Mint",
    isMenu: true,
    icon: <GlobalOutlined />,
  },
  // {
  //   path: "shark-point",
  //   key: v4(),
  //   element: null,
  //   title: "Shark Point",
  //   isMenu: true,
  //   icon: <GlobalOutlined />,
  // },
  {
    path: "ino",
    key: v4(),
    element: null,
    title: "INO",
    isMenu: true,
    icon: <GlobalOutlined />,
  },
]
