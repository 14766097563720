





import { Button, Dropdown } from 'antd';
import React, { FC, useState } from 'react'
import ConnectModal from './ConnectModal';
import { useWeb3React } from '@web3-react/core';
import { getEllipsisTxt } from '~/common/utils/formatters';
import ConnectWalletButton from './ConnectWalletButton';
import Jazzicons from '~/components/Jazzicons';
import DisconnectModal from './DisconnectModal';
import { DownloadOutlined, OrderedListOutlined, PartitionOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '~/hooks/useWindowSize';

const styles = {
  account: {
    height: "42px",
    padding: "12 20px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    borderRadius: "100px",
    cursor: "pointer",
  },
  button: {
    height: "40px",
    padding: "0 20px",
    textAlign: "center",
    fontWeight: "600",
    letterSpacing: "0.2px",
    fontSize: "15px",
    margin: "20px 20px",
    border: "none",
    background: "black",
    color: "white"
  },
  text: {
    // color: "white"
  },
  modalTitle: {
    marginBottom: "20px",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    fontWeight: "700",
    fontSize: "20px"
  }
} as const;

type IAccountWalletProps = {
}

const AccountWallet: FC<IAccountWalletProps> = (props: IAccountWalletProps) => {
  const { width } = useWindowSize();
  const { account, connector } = useWeb3React();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const disconnect = async () => {

    setIsModalOpen(false);
    localStorage.removeItem("connectorId");
    if (connector.deactivate) {
      connector.deactivate();
    } else {
      connector.resetState();
    }
    // @ts-expect-error close can be returned by wallet
    if (connector && connector.close) {
      // @ts-expect-error close can be returned by wallet
      await connector.close();
    }
  };
  return (
    <>
      {account === undefined ? (
        <ConnectWalletButton />
      ) : (
        <>
          <Dropdown
            menu={{
              items: [
                // {
                //   onClick: () => { navigate("my-referal") },
                //   label: "My Referal",
                //   key: "your-referal",
                //   icon: <PartitionOutlined />
                // },
                // {
                //   onClick: () => { navigate("inventory") },
                //   label: "Inventory",
                //   key: "Inventory",
                //   icon: <OrderedListOutlined />
                // },

                {
                  onClick: () => setIsModalOpen(true),
                  label: "Disconnect",
                  key: "Disconnect",
                  icon: <DownloadOutlined />
                }
              ]
            }}
          >
            <div
              style={{
                ...styles.account,
                padding: width > 900 ? "12px 15px" : "4px 6px",
                border: width > 900 ? "1px solid #F95F0B" : "1px solid #F95F0B",
                background: width > 900 ? "#000" : "transparent"
              }}
            >
              {account && typeof account === "string" && (
                <p style={{ marginRight: "5px", }}>{getEllipsisTxt(account, 4)}</p>
              )}
              {width > 900 &&
                <Jazzicons seed={account} />
              }
            </div>
          </Dropdown>
          <DisconnectModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} disconnect={disconnect} />
        </>
      )}
    </>
  )
}
export default AccountWallet;
