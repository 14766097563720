import { IEnvConfig } from "../dto";

const config: IEnvConfig = {
  name: "QA",
  CONNECTORS: {
    ROOT: {
      baseUrl: "https://i198e9817g.execute-api.ap-southeast-1.amazonaws.com/prod/mvs-dapp"
    }
  },
  applyChainIds: [84531, 97],
  customSMC: {
    84531: {
      VAULT_FACTORY: "0x03181A24dBE0444D1f65E627c7BFa8E9745E49BD".trim(),
      BUSD: "0xFDeacA5bc0E8DC0Df189F233d84578e037f908d7".trim(),
      QUEST_POOL: "0xca8B840932c0Aa34B9E425774c15074B56877fF2".trim(),
      FREE_MINT_POOL: "0x9b6b86cE5AAd545B45F57b3E32E5314cD5607b8a".trim(),
      STAKE_MULTIPLE_ERC721: "".trim(),
    }
  }
}

export default config;
