import React, { FC, useCallback, useState } from 'react'

import "./index.less"
import BaseView from '~/components/BaseView';
import { Avatar, Button, Card, Col, Image, Input, List, Row, Table } from 'antd';
import HeaderInfo from '~/layouts/containers/HeaderInfo';
import { useWindowSize } from '~/hooks/useWindowSize';
import { useThemeStore } from "~/stores/themeStore";
import { SearchOutlined } from '@ant-design/icons';
import { useListPool } from '../StakeNftView/hooks/useListPool';
import { ColumnsType } from 'antd/es/table';
import TableControl from '~/@ui/TableControl';
import { pipeLongTextUi, pipeText } from '~/common/utils/common.utils';

import ImgElite from "~/assets/images/elite.png"
import ImgLuminary from "~/assets/images/luminary.png"
import ImgProdigy from "~/assets/images/prodigy.png"
import ImgTitan from "~/assets/images/titan.png"


const { Meta } = Card;

interface ILeaderBoard {
  no: string,
  sharkPoint: number,
  address: string,
  rank: string,
  tier: string,
}


const dataSource: ILeaderBoard[] = [
  {
    no: '1',
    sharkPoint: 1234,
    address: '0x68a44D9306084DDBc7b53142A218cc9cAb795c63',
    rank: ImgTitan,
    tier: "Shark Titan"
  },
  {
    no: '2',
    sharkPoint: 123,
    address: '0x68a44D9306084DDBc7b53142A218cc9cAb795c63',
    rank: ImgElite,
    tier: "Shark Elite"
  },
  {
    no: '3',
    sharkPoint: 12,
    address: '0x68a44D9306084DDBc7b53142A218cc9cAb795c63',
    rank: ImgProdigy,
    tier: "Shark Prodigy"
  },
  {
    no: '4',
    sharkPoint: 1,
    address: '0x68a44D9306084DDBc7b53142A218cc9cAb795c63',
    rank: ImgLuminary,
    tier: "Shark Luminary"
  },
];

type IProps = {
}

const pageSize = 10;

const LeaderBoardView: FC<IProps> = (props: IProps) => {


  const { width } = useWindowSize();
  const { themConfig } = useThemeStore();
  const { colorMode } = themConfig;

  const [isLoading, setIsLoading] = useState(false);
  const [keySearch, setKeySearch] = useState(null);
  const [data, setData] = useState<ILeaderBoard[]>([]);


  const columns: ColumnsType<ILeaderBoard> = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      render: (value: string, record: any, index: number) => {
        return <span style={{ fontFamily: 'Jost-Bold', fontSize: "16px", }}>
          {value}
        </span>
      },
      width: 70
    },
    {
      title: 'Tier',
      dataIndex: 'tier',
      key: 'tier',
      render: (value: string, record: any, index: number) => {
        return <div style={{ alignItems: "center", alignSelf: "center", display: "flex" }}>
          <Avatar src={record.rank} size={36} />
          <span
            style={{
              fontFamily: 'Jost-Bold',
              ...colorMode?.primary,
              textTransform: "uppercase",
              fontSize: "16px",
              paddingLeft: 10
            }}>
            {value}
          </span>
        </div>
      },
      width: 250
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (value: string, record: any, index: number) => {
        return <span style={{ fontFamily: 'Jost-Bold', ...colorMode?.primary, textTransform: "uppercase", fontSize: "16px", }}>
          {width <= 900 ? pipeLongTextUi(value, 5) : pipeLongTextUi(value, 15)}
        </span>
      },
      width: 250
    },
    {
      title: 'Shark Point',
      dataIndex: 'sharkPoint',
      key: 'sharkPoint',
      render: (value: string, record: any, index: number) => {
        return <span style={{ fontFamily: 'Jost-Bold', ...colorMode?.primary, textTransform: "uppercase", fontSize: "16px", }}>
          {value}
        </span>
      },
      width: 150
    },

  ];

  const loadData = useCallback(async ({ pageIndex, pageSize }: { pageIndex: number, pageSize: number }) => {
    // const rs = await storesNetworkService.list({
    //   pageIndex: 1,
    //   pageSize: 1000,
    //   isAll: true,
    // })
    return {
      total: 20,
      data: dataSource,
    }
  }, [])

  return (
    <BaseView>

      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div
          style={{ maxWidth: width <= 992 ? "100%" : 1000 }}
        >

          <HeaderInfo
            title='Leader Board'
            description={`Leader board`}
          />

          <Row
            className='borderLinearInput'
            style={{
              maxWidth: width <= 500 ? "100%" : 420,
            }}>
            <Input
              style={{
                borderRadius: 24,
                height: 42,
                paddingLeft: 20,
                color: "#fff"
              }}
              placeholder='Search Your Address'

              value={keySearch}
              onChange={(e) => {
                setKeySearch(e.target.value)
              }}
              prefix={<SearchOutlined />}
            />
          </Row>

          <Row style={{ width: "100%", marginTop: 30, }}>

            <TableControl
              columns={columns}
              loadData={loadData}
            />

          </Row>

        </div>
      </div>

      <div style={{ minHeight: "10vh", width: "100%" }} />

    </BaseView>
  )
}
export default LeaderBoardView;
