import { IEvmChainConfig } from "./dto";

export const EVM_CHAIN_LIST: { [chainId: number]: IEvmChainConfig } = {
  1: {
    chainId: 1,
    rpcUrls: [
      "https://rpc.ankr.com/eth",
      "https://cloudflare-eth.com"
    ],
    chainName: "ETH",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://etherscan.io"],
    logo: "https://dd.dexscreener.com/ds-data/chains/ethereum.png"
  },
  56: {
    chainId: 56,
    rpcUrls: [
      "https://bsc-dataseed.binance.org"
    ],
    chainName: "BSC Mainnet",
    nativeCurrency: {
      name: "BNB Chain",
      symbol: "BNB",
      decimals: 18
    },
    blockExplorerUrls: ["https://bscscan.com/"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/bsc.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/bsc.png",
  },

  97: {
    chainId: 97,
    chainName: "BSC Testnet",
    nativeCurrency: {
      name: 'BNB',
      symbol: 'bnb',
      decimals: 18
    },
    rpcUrls: [
      "https://data-seed-prebsc-2-s1.binance.org:8545",
      "https://data-seed-prebsc-2-s1.binance.org:8545",
      "https://data-seed-prebsc-2-s1.binance.org:8545"
    ],
    blockExplorerUrls: ["https://testnet.bscscan.com"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/bsc.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/bsc.png"
  },
  // Polygon
  137: {
    chainId: 137,
    rpcUrls: [
      "https://polygon-rpc.com"
    ],
    chainName: "Polygon Mainnet",
    nativeCurrency: {
      name: "Matic",
      symbol: "MATIC",
      decimals: 18
    },
    blockExplorerUrls: ["https://polygonscan.com"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/polygon.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/polygon.png"
  },
  80001: {
    chainId: 80001,
    chainName: "Polygon Mumbai",
    nativeCurrency: {
      "name": "MATIC",
      "symbol": "MATIC",
      "decimals": 18
    },
    rpcUrls: [
      "https://matic-mumbai.chainstacklabs.com",
      "https://rpc-mumbai.maticvigil.com",
      "https://matic-testnet-archive-rpc.bwarelabs.com"
    ],
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/polygon.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/polygon.png"
  },
  84531: {
    chainName: "Base Goerli Testnet",
    rpcUrls: [
      "https://goerli.base.org"
    ],
    nativeCurrency: {
      name: "Goerli Ether",
      symbol: "ETH",
      decimals: 18
    },
    chainId: 84531,
    blockExplorerUrls: ["https://goerli.basescan.org"],
    iconUrls: ["https://d111ej16yd04xs.cloudfront.net/upload/mvs/res/d42a9654-0db1-496f-96cf-87f35b1d130f.png"],
    logo: "https://d111ej16yd04xs.cloudfront.net/upload/mvs/res/d42a9654-0db1-496f-96cf-87f35b1d130f.png",

  },
  10: {
    chainName: "OP Mainnet",
    rpcUrls: [
      "https://mainnet.optimism.io"
    ],
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18
    },
    chainId: 10,
    blockExplorerUrls: [
      "https://optimistic.etherscan.io"
    ],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/optimism.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/optimism.png"
  },
  420: {
    chainName: "OP Goerli Testnet",
    rpcUrls: [
      "https://optimism-goerli.public.blastapi.io",
      "https://goerli.optimism.io"
    ],

    nativeCurrency: {
      name: "Goerli Ether",
      symbol: "ETH",
      decimals: 18
    },
    chainId: 420,
    blockExplorerUrls: [
      "https://goerli-optimism.etherscan.io"
    ],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/optimism.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/optimism.png"
  }
}
