import React, { useState } from 'react';
import { Alert, Button, Checkbox, Col, Form, Input, Layout, Row, Spin, Image, } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import BannerLogo from "~/assets/logo/banner_logo_feed.png"
import Banner from "~/assets/logo/banner.png"

import Title from 'antd/es/typography/Title';
import { useWindowSize } from '~/hooks/useWindowSize';

const WhitelistView: React.FC = () => {

  const navigate = useNavigate();
  const { height, width } = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  return (
    <Spin tip="Loading..." spinning={isLoading}  >

      <Layout
        style={{
          justifyContent: 'center',
          alignItems: "center",
          width: "100%",
          // maxHeight: width <= 1120 ? "auto" : height,
        }}
      >

        <Row
          gutter={[24, 0]}
          className='login'
          style={{
            width: "100%",
            // height: height,
            // background: "#100F1E",

            backgroundImage: `url(${Banner})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >

          <Col xs={24} sm={24} md={24} lg={24} xl={12} style={{ textAlign: "center" }}>

            <Row gutter={[24, 2]} justify={"center"} style={{ paddingTop: "50px" }}>

              <Col
                xs={24} sm={24} md={24} lg={12} xl={12}
              >

                <Title
                  style={{
                    color: "#fff",
                    fontFamily: "Jost-Bold",
                    textAlign: "center",
                    fontSize: "62px",
                  }}
                  level={2}
                >
                  WELCOME TO NFTFEED INTERNAL TESTNET
                </Title>

              </Col>

              <Col
                xs={24} sm={24} md={24} lg={12} xl={12}
              >
                <div style={{ textAlign: "start", margin: "auto", maxWidth: "700px", paddingLeft: width > 500 && "10%" }}>
                  <p
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      lineHeight: "28px",
                      opacity: 0.8,
                      width: "100%",
                      textAlign: "left",
                      fontFamily: "Jost-Medium",
                    }}
                  >
                    Dear Sharkies,
                  </p>
                  <p
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      lineHeight: "28px",
                      opacity: 0.8,
                      width: "100%",
                      textAlign: "left",
                      fontFamily: "Jost-Medium",
                    }}
                  >
                    The Internal Testnet only applies to users who meet the following conditions:
                  </p>

                  <p
                    style={{
                      paddingLeft: "10px",
                      color: "#fff",
                      fontSize: "20px",
                      lineHeight: "22px",
                      textAlign: "left",
                      fontFamily: "Jost-Medium",
                    }}
                  >
                    - Earn at least 1,200 XP at
                    <span
                      className='textPrimary'
                      style={{
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        window.open("https://zealy.io/c/nftfeed/questboard")
                      }}
                    >
                      {` Zealy Quest`}
                    </span>
                  </p>

                  <p
                    style={{
                      paddingLeft: "10px",
                      color: "#fff",
                      fontSize: "20px",
                      lineHeight: "22px",
                      textAlign: "left",
                      fontFamily: "Jost-Medium",
                    }}
                  >
                    - Already Claim
                    <span
                      className='textPrimary'
                      style={{
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        window.open("#")
                      }}
                    >
                      {` TUSD `}
                    </span>
                    token
                  </p>

                  <p
                    style={{
                      paddingLeft: "10px",
                      color: "#fff",
                      fontSize: "20px",
                      lineHeight: "22px",
                      textAlign: "left",
                      fontFamily: "Jost-Medium",
                    }}
                  >
                    - Prepare enough ETH Faucet - check
                    <span
                      className='textPrimary'
                      style={{
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        window.open("https://twitter.com/NFTFeedOfficial/status/1671785297997365251")
                      }}
                    >
                      {` Guideline `}
                    </span>
                  </p>

                </div>

                <div style={{ textAlign: "start", margin: "auto", maxWidth: "650px", paddingLeft: width > 500 && "10%" }}>
                  <p
                    style={{
                      paddingLeft: "10px",
                      color: "#fff",
                      fontSize: "20px",
                      lineHeight: "28px",
                      textAlign: "center",
                      fontFamily: "Jost-Medium",
                    }}
                  >
                    If you are not qualified, please complete the above tasks or wait for the upcoming External Testnet event.
                  </p>
                </div>

              </Col>

            </Row>

          </Col>

          <Col
            xs={24} sm={24} md={24} lg={24} xl={12}
            style={{
              textAlign: "center",
              paddingTop: "0%"
            }}
          >

            <Image
              src={BannerLogo}
              preview={false}
              style={{
                objectFit: "contain",
                height: width <= 1120 ? "auto" : "915px",
              }}
            />

          </Col>

        </Row>

      </Layout >

    </Spin >

  )
}

export default WhitelistView;
