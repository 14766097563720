

import "./table-control.less";
import { Table, TableProps } from 'antd';
import { ColumnGroupType, ColumnsType } from 'antd/lib/table';
import React, { FC, useCallback, useEffect, useState } from 'react';


interface ITableControlProps<RecordType> extends Omit<TableProps<RecordType>, "dataSource"> {
  loadData: (page: { pageIndex: number, pageSize: number }) => Promise<{
    total: number;
    data: RecordType[];
  }> | {
    total: number;
    data: RecordType[];
  };
  defaultPageSize?: number;
}


const TableControl = <RecordType extends object = any>(props: ITableControlProps<RecordType>) => {
  const { loadData, defaultPageSize = 10, pagination, ...restProps } = props;
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);


  const fetchData = useCallback(async (pageIndex: number, pageSize: number) => {
    setLoading(true);
    try {
      const { data, total } = await loadData({ pageIndex, pageSize })
      setData(data);
      setTotal(total);
    } catch (error) {

    }
    setLoading(false);
  }, [loadData]);

  useEffect(() => {
    fetchData(pageIndex, pageSize);
  }, [fetchData, pageIndex, pageSize]);



  const handlePageChange = (pageIndex: number, size?: number) => {
    setPageIndex(pageIndex);
  };


  const onShowSizeChange = (pageIndex: number, size: number) => {
    setPageSize(size)
  }

  return (
    <div className='table-control' style={{ height: '100%' }}>
      <Table<RecordType>

        {...restProps}

        scroll={{ x: 'max-content', y: '100%' }}
        dataSource={data}
        loading={loading}

        pagination={{
          current: pageIndex,
          pageSize: pageSize,
          total: total,
          onChange: handlePageChange,
          showSizeChanger: true,
          onShowSizeChange: onShowSizeChange,
          ...pagination
        }}

      />
    </div>
  )
}

export default TableControl
