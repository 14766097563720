import { IEnvConfig } from "../dto";

const config: IEnvConfig = {
  name: "DEVELOPMENT",
  CONNECTORS: {
    ROOT: {
      baseUrl: "https://i198e9817g.execute-api.ap-southeast-1.amazonaws.com/prod/mvs-dapp"
    }
  },
  applyChainIds: [10, 420, 84531],
  customSMC: {
    84531: {
      VAULT_FACTORY: "0x03181A24dBE0444D1f65E627c7BFa8E9745E49BD".trim(),
      BUSD: "0xFDeacA5bc0E8DC0Df189F233d84578e037f908d7".trim(), // tesnet DOO DOO
      QUEST_POOL: "0xca8B840932c0Aa34B9E425774c15074B56877fF2".trim(),
      FREE_MINT_POOL: "0xc5b1245a7981A06c0690581e09E3244711f1C93a".trim(),
      STAKE_MULTIPLE_ERC721: "".trim(),
    },
    420: {
      VAULT_FACTORY: "".trim(),
      BUSD: "".trim(), // tesnet DOO DOO
      QUEST_POOL: "".trim(),
      FREE_MINT_POOL: "".trim(),
      STAKE_MULTIPLE_ERC721: "0xDA75f538b73f58C005b5542BC5d30da0b09aBdC9".trim(),
    },
    10: {
      VAULT_FACTORY: "".trim(),
      BUSD: "".trim(), // tesnet DOO DOO
      QUEST_POOL: "".trim(),
      FREE_MINT_POOL: "".trim(),
      STAKE_MULTIPLE_ERC721: "0x12CcE7bcA11364Da0D120Fb82B4042a46a8Cf586".trim(),
    },
  }
}

export default config;
