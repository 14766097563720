import { useState, useEffect, useCallback, useMemo, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { IRouter } from "~/routers";
import { mainRouter } from "~/views/main/main.router";



export class MenuLayoutItem {

  key: string;
  path: string;
  title: string;
  isMenu?: boolean;
  icon?: React.ReactNode;
  rootPath: string;
  children?: MenuLayoutItem[];
  closable: boolean = true;
}

export interface IGlobalState {
  device: "MOBILE" | "DESKTOP",
  collapsed: boolean
}

function getGlobalState(): IGlobalState {
  const device = /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent) ? 'MOBILE' : 'DESKTOP';
  const collapsed = device !== 'DESKTOP';
  return {
    device,
    collapsed
  };
}
function pipePath(path: string) {
  const regexConfig = /(^\/+|\/+$)/mg;
  return path.replace(regexConfig, "")
}


const mapLayoutItem = (layoutResoucies: IRouter[], prefixPath: string = ""): MenuLayoutItem[] => {

  const outputs = new Array<MenuLayoutItem>();
  for (const item of layoutResoucies) {
    const { children = [], isMenu = false, path } = item;
    const input = new MenuLayoutItem();
    input.key = item.key;
    input.path = item.path;
    input.icon = item.icon;
    input.title = item.title;
    const mixPath = pipePath(prefixPath + "/" + path)
    input.rootPath = mixPath;
    input.isMenu = isMenu;

    if (children.length > 0) {
      const child = mapLayoutItem(children, mixPath);
      input.children = child;
    }
    outputs.push(input);
  }

  return outputs;
}

const mapMenuLayoutItem = (layoutResoucies: IRouter[], prefixPath: string = ""): MenuLayoutItem[] => {
  const outputs = new Array<MenuLayoutItem>();
  for (const item of layoutResoucies) {
    const { children = [], isMenu = false, path } = item;
    if (!isMenu) {
      continue;
    }
    const input = new MenuLayoutItem();
    input.key = item.key;
    input.path = item.path;
    input.icon = item.icon;
    input.title = item.title;
    const mixPath = pipePath(prefixPath + "/" + path)
    input.rootPath = mixPath;

    if (children.length > 0) {
      const child = mapMenuLayoutItem(children, mixPath);
      input.children = child;
    }
    outputs.push(input);
  }

  return outputs;
}

const findParentNodes = (tree: MenuLayoutItem[], key: string) => {
  const parentNodes = [];
  function traverse(currentNodes: MenuLayoutItem[], parentNode = null) {
    for (let i = 0; i < currentNodes.length; i++) {
      const currentNode = currentNodes[i];
      if (currentNode.key === key) {
        if (parentNode !== null) {
          parentNodes.push(parentNode);
        }
        break;
      } else {
        if (currentNode.children !== undefined) {
          traverse(currentNode.children, currentNode);
        }
      }
    }
  }
  traverse(tree)
  return parentNodes;
}
const mapFlatLayoutResource = (layoutResoucies: MenuLayoutItem[]): MenuLayoutItem[] => {
  let outputs = new Array<MenuLayoutItem>();
  for (const item of layoutResoucies) {
    const { children = [] } = item;
    if (children.length > 0) {
      const data = mapFlatLayoutResource(children);
      outputs = outputs.concat(data);
    } else {
      outputs.push(item);
    }
  }

  return outputs;
}

interface ILayoutConfig {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  device: "MOBILE" | "DESKTOP";
  menus: MenuLayoutItem[];
  tabs: MenuLayoutItem[];
  layoutResoucies: MenuLayoutItem[];
  flatLayoutResource: MenuLayoutItem[];
  selectedKey: string;
  setSelectedKey: (key: string) => void;

  openKeys: string[];
  setOpenKeys: (keys: string[]) => void;
  setActiveTab: (key: string) => void;
  addTab: (tab: MenuLayoutItem, searchParamsStr?: string) => void;
  removeTab: (targetKey: string) => void;
  removeOtherTab: () => void;
  removeAllTab: () => void;
}


const LayoutConfigContext = createContext<ILayoutConfig | undefined>(undefined);

const LayoutConfigProvider = ({ children }: { children: React.ReactNode; }) => {

  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(getGlobalState().collapsed);
  const [device, setDevice] = useState(getGlobalState().device);

  const [layoutResoucies, setLayoutResoucies] = useState(mapLayoutItem(mainRouter));
  const [menus, setMenus] = useState(mapMenuLayoutItem(mainRouter))
  const [tabs, setTabs] = useState<MenuLayoutItem[]>([]);

  const [selectedKey, setSelectedKey] = useState("");

  const [openKeys, setOpenKeys] = useState([]);

  const [searchParamsView, setSearchParamsView] = useState<{ [key: string]: string }>({});

  const setActiveTab = useCallback((key: string) => {
    console.log(`---------setActiveTab----------`);
    console.log({ key });
    console.log(`-------------------`);
    const tab = tabs.find(tab => tab.key === key);
    if (tab) {
      setSelectedKey(tab.key);
      const searchParamsStr = searchParamsView[tab.key] || "";
      navigate(`${tab.rootPath}${searchParamsStr}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, tabs]);



  const addTab = useCallback((menu: MenuLayoutItem, searchParamsStr = "") => {
    if (menu.children && menu.children.length > 0) {
      return;
    }

    const indexFind = tabs.findIndex(tab => tab.key === menu.key);
    if (indexFind < 0) {
      const layoutItem = new MenuLayoutItem();
      layoutItem.key = menu.key;
      layoutItem.icon = menu.icon;
      layoutItem.title = menu.title;
      layoutItem.path = menu.path;
      layoutItem.rootPath = menu.rootPath;
      // tabs.push(layoutItem);
      setTabs([
        ...tabs,
        layoutItem
      ]);
    }
    setSearchParamsView({
      ...searchParamsView,
      [menu.key]: searchParamsStr
    })

    setSelectedKey(menu.key);
    navigate(`${menu.rootPath}${searchParamsStr || searchParamsView[menu.key] || ""}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, tabs]);



  const removeOtherTab = useCallback(() => {
    const selectedTab = tabs.find(t => t.key === selectedKey);
    if (selectedTab) {
      setTabs([selectedTab])
    }

  }, [selectedKey, tabs]);

  const removeAllTab = useCallback(() => {
    const firstMenu = mapFlatLayoutResource(layoutResoucies)[0];
    setTabs([firstMenu]);
    setSelectedKey(firstMenu.key);
    navigate(firstMenu.rootPath)

  }, [layoutResoucies, navigate]);

  const removeTab = useCallback((targetKey: string) => {

    const newTabs = tabs.filter(t => t.key !== targetKey);
    if (newTabs.length === 0) {
      const firstMenu = mapFlatLayoutResource(layoutResoucies)[0];
      setTabs([firstMenu]);
      setSelectedKey(firstMenu.key);
      navigate(firstMenu.rootPath)
    } else {
      const indexFind = tabs.findIndex(tab => tab.key === targetKey);
      if (indexFind > -1) {
        if (indexFind === 0) {
          if (tabs.length > 1) {
            setSelectedKey(tabs[1].key);
            navigate(tabs[1].rootPath)
          }
        } else {
          if (targetKey === selectedKey) {
            setSelectedKey(tabs[indexFind - 1].key)
            navigate(tabs[indexFind - 1].rootPath)
          }
        }
        setTabs([
          ...newTabs,
        ]);
      }
    }

  }, [layoutResoucies, navigate, selectedKey, tabs])

  useEffect(() => {
    const parrents = findParentNodes(layoutResoucies, selectedKey);
    setOpenKeys(parrents.map(v => v.key))
  }, [layoutResoucies, selectedKey])

  const values = useMemo(
    () => ({
      collapsed,
      setCollapsed,
      device,
      menus,
      tabs,
      layoutResoucies,
      selectedKey,
      setSelectedKey,
      openKeys,
      setOpenKeys,
      setActiveTab,
      flatLayoutResource: mapFlatLayoutResource(layoutResoucies),
      addTab,
      removeTab,
      removeAllTab,
      removeOtherTab
    }),
    [addTab, collapsed, device, layoutResoucies, menus, openKeys, removeAllTab, removeOtherTab, removeTab, selectedKey, setActiveTab, tabs]
  )

  return <LayoutConfigContext.Provider value={values}>{children}</LayoutConfigContext.Provider>

}

const useLayoutConfig = () => {
  const context = useContext(LayoutConfigContext);
  if (context === undefined) {
    throw new Error('useWallet hook must be used with a LayoutConfigContext component')
  }
  return context;
}

export {
  LayoutConfigProvider,
  useLayoutConfig,
}



