import React, { Suspense } from 'react';
import "./index.less";
import { Divider, Drawer, Layout, Space, Image, theme as antTheme } from 'antd';
import { Outlet } from 'react-router-dom';

import { useLayoutConfig } from '~/stores/layoutConfig';
import MainHeader from './MainHeader';
import MainMenu from './MainMenu';
import MainTabs from './MainTabs';
import { useWindowSize } from '~/hooks/useWindowSize';
import { useThemeStore } from '~/stores/themeStore';
import IcTele from "~/assets/header/ic_tele.png"
import IcTw from "~/assets/header/ic_tw.png"
import IcGitBook from "~/assets/header/ic_gitbook.png"
import IcDis from "~/assets/header/icon-discord.svg"
import WebLogo from '~/assets/logo/logo_no_title.png';
import { useNavigate } from 'react-router-dom';

const { Sider, Content } = Layout;



const MainLayout = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isMobile = width <= 900;
  const token = antTheme.useToken();
  const { setCollapsed, collapsed, menus, openKeys, selectedKey } = useLayoutConfig();
  const { themConfig } = useThemeStore();
  const { colorMode } = themConfig;

  const toggle = () => {
    setCollapsed(!collapsed)
  };

  const renderSocial = () => {
    return (

      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          textAlign: "center",
          position: "absolute",
          bottom: 15,
          transition: "all 250ms ease-in-out"
        }}
      >

        {width <= 900 ?

          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              textAlign: "center",
              display: "flex",
              marginBottom: 15
            }}
          >
            <img src={WebLogo} alt="" style={{ marginRight: collapsed ? '2px' : '5px', width: 35, height: 35, }} />
            <span style={{ fontSize: '16px', fontFamily: "Jost-Bold", color: "#fff" }}>NFTFeed</span>
          </div>
          :
          <div
            className="logo"
            style={{
              width: collapsed ? 80 : 200,
              transition: "all 250ms ease-in-out"
            }}
            onClick={() => navigate("/")}
          >
            <img src={WebLogo} alt="" style={{ marginRight: collapsed ? '2px' : '5px', }} />
            {!collapsed && <span style={{ fontSize: '16px', fontFamily: "Jost-Bold", color: "#fff" }}>NFTFeed</span>}
          </div>
        }

        {!collapsed &&
          <>

            <div
              style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}
            >

              <div style={{ margin: "auto 10px" }}>
                <Image
                  src={IcTele}
                  preview={false}
                  style={{ cursor: "pointer", }}
                  onClick={() => {
                    window.open("https://t.me/NFTFeedOfficial")
                  }}
                />
              </div>

              <div style={{ margin: "auto 10px" }}>
                <Image src={IcTw} preview={false}
                  style={{ cursor: "pointer", }}
                  onClick={() => {
                    window.open("https://twitter.com/NFTFeedOfficial")
                  }}
                />
              </div>

              <div style={{ margin: "auto 10px" }}>
                <Image src={IcDis} preview={false}
                  style={{ cursor: "pointer", }}
                  onClick={() => {
                    window.open("https://discord.com/invite/nftfeed")
                  }}
                />
              </div>

              <div style={{ margin: "auto 10px" }}>
                <Image src={IcGitBook} preview={false}
                  style={{ cursor: "pointer", }}
                  onClick={() => {
                    window.open("https://nftfeed.gitbook.io/nftfeed/")
                  }}
                />
              </div>
            </div>

            <div style={{ fontSize: '12px', fontFamily: "Jost", color: "#656565", textAlign: "center", marginTop: 15 }}>
              <span >Copyright © 2022 NftFeed Ltd</span>
            </div>
          </>
        }

      </div>
    )
  }

  return (
    <Layout className="layout-page">
      <MainHeader collapsed={collapsed} toggle={toggle} />
      <Layout>
        {!isMobile ? (
          <Sider
            className="layout-page-sider"
            trigger={null}
            collapsible
            style={{ backgroundColor: colorMode?.bgPrimary }}
            collapsedWidth={isMobile ? 0 : 80}
            collapsed={collapsed}
            breakpoint="md"
          >
            <div style={{
              width: "100%",
              height: "100%",
              justifyContent: "space-between",
            }}>
              <MainMenu
                menus={menus}
                openKeys={openKeys}
                selectedKey={selectedKey}
              />

              {renderSocial()}
            </div>
          </Sider>
        ) : (
          <Drawer
            width="200"
            placement="left"
            bodyStyle={{ padding: "10px 0px 0px", height: '100%', background: colorMode?.bgPrimary, margin: 0 }}
            closable={false}
            onClose={toggle}
            open={!collapsed}
            style={{ margin: "50px 0px 0px", background: colorMode?.bgPrimary }}
          >
            <div style={{
              width: "100%",
              height: "100%",
              justifyContent: "space-between",
            }}>

              <MainMenu
                menus={menus}
                openKeys={openKeys}
                selectedKey={selectedKey}
              />
              {renderSocial()}
            </div>
          </Drawer>
        )}


        <Content className="layout-page-content" style={{ maxHeight: "100vh" }}>
          <Suspense fallback={null}>
            <Outlet />
          </Suspense>
        </Content>


      </Layout>
    </Layout>
  )
}
export default MainLayout;
