



import { QuestionCircleOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { SelectInfo } from 'rc-menu/lib/interface';

import { FC } from 'react'
import { useNavigate } from 'react-router-dom';
import { MenuLayoutItem, useLayoutConfig } from '~/stores/layoutConfig';
import { mainRouter } from '~/views/main/main.router';


type IMainMenuProps = {
  menus: MenuLayoutItem[];
  openKeys?: string[];
  selectedKey: string;
}

const MainMenu: FC<IMainMenuProps> = (props: IMainMenuProps) => {

  const { menus, openKeys, selectedKey } = props;
  const navigate = useNavigate();
  const { device, setCollapsed, flatLayoutResource, addTab, setOpenKeys } = useLayoutConfig();
  const onMenuClick = (item: SelectInfo) => {
    const tab = flatLayoutResource.find(v => v.key === item.key);
    if (tab.path !== "free-mint" && tab.path !== "shark-point") {
      addTab(tab);
    }
    if (device !== 'DESKTOP') {
      setCollapsed(true);
    }
  };



  const getTitie = (view: MenuLayoutItem) => {
    const { icon = <QuestionCircleOutlined />, title = "NO TITLE" } = view;
    return (
      <span style={{ display: 'flex', alignItems: 'center', paddingTop: "2px" }}>
        {icon}
        <span>{title}</span>
      </span>
    );
  };

  const mapMenus = (menus: MenuLayoutItem[]): ItemType[] => {
    const outputs = new Array<ItemType>();
    for (const item of menus) {
      const { children = [], path } = item;
      const input: ItemType = {
        key: item.key,
        label: getTitie(item),
      }
      if (children.length > 0) {
        const child = mapMenus(children);
        Object.assign(input, {
          children: child
        })
      }
      outputs.push(input);
    }
    return outputs;
  }

  const urlWebsite = (url: string) => {
    if (url === "free-mint") {
      return "https://mint.nftfeed.guru"
    } if (url === "shark-point") {
      return "https://point.nftfeed.guru"
    }
    return "https://mint.nftfeed.guru/nft-collection/0x268174ff68633901a2ec6511b33c590aac4fe263"
  }

  return (
    <Menu
      style={{ padding: 16 }}
      mode="inline"
      selectedKeys={[selectedKey]}
      openKeys={openKeys}
      onOpenChange={setOpenKeys}
      onSelect={k => onMenuClick(k)}
      onClick={(e) => {
        const url = mainRouter.find(i => i.key === e.key);
        if (url) {
          if (url.path === "free-mint"
            || url.path === "shark-point"
            || url.path === "ino") {
            window.open(urlWebsite(url.path))
          } else {
            navigate(url.path)
          }
        }
      }}
      className="layout-page-sider-menu text-2"
      items={mapMenus(menus)}
    ></Menu>
  )
}
export default MainMenu;
