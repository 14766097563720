
import { theme } from "antd";
import { ConfigProviderProps, ThemeConfig } from "antd/es/config-provider";
import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";


type IThemeMode = "default" | "compact";
type IThemeStyle = "light" | "dark";



type SetStateType<T> = Dispatch<SetStateAction<T>>

// type SetStateType<T = any> = (value: T) => void;


interface IThemeConfig extends ThemeConfig {
  colorMode: {
    bgPrimary: string;
    bgSecondary: string;
    primary: any;
  },

}

interface IThemeStore {
  mode: IThemeMode;
  themeStyle: IThemeStyle;
  setThemeStyle: SetStateType<IThemeStyle>;
  themConfig: IThemeConfig
  changeTheme: (themeOption: { mode?: "default" | "compact", themeStyle?: "light" | "dark" }) => void;
}
const ThemeStoreContext = createContext<IThemeStore | undefined>(undefined);
// 'linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)';
// const colorPrimary = '#E83CBD';
const colorPrimary = '#de0245';

const ThemeStoreProvider = ({ children }: { children: React.ReactNode; }) => {

  const { token } = theme.useToken();
  const [mode, setMode] = useState<IThemeMode>("default");
  const [themeStyle, setThemeStyle] = useState<IThemeStyle>("dark");
  const [themConfig, setThemConfig] = useState<IThemeConfig>({
    colorMode: null,
  });


  useEffect(() => {

    // const themeVars = {};
    const algorithm = [];
    if (themeStyle === 'dark') {
      algorithm.push(theme.darkAlgorithm)
    } else {
      algorithm.push(theme.defaultAlgorithm)
    }
    if (mode === "compact") {
      algorithm.push(theme.compactAlgorithm)
    }
    setThemConfig({
      algorithm,
      token: {
        //colorPrimary,
      },
      colorMode: {
        bgPrimary: "#292438",
        bgSecondary: "radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%)",

        primary: {
          "background": "radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%)",
          "-webkit-background-clip": "text",
          "-webkit-text-fill-color": "transparent",
          "backgroundClip": "text",
        }
      },
      components: {
        Layout: {
          colorBgHeader: theme.defaultConfig.token.colorBgBase
        },
        // Form: {
        //   colorBgContainer: token.colorBgTextActive
        // }
        // Slider: {

        // }
        // Input: {
        //   colorBgLayout: theme.defaultConfig.token.colorBgBase
        // }
      }
    })
  }, [mode, themeStyle, token])



  const changeTheme = useCallback((themeOption: { mode?: "default" | "compact", themeStyle?: "light" | "dark" }) => {
    if (!themeOption || Object.keys(themeOption).length === 0) {
      return;
    }
    const { mode = undefined, themeStyle = undefined } = themeOption;
    if (!mode && !themeStyle) {
      return;
    }
    if (mode) {
      setMode(mode);
    }
    if (themeStyle) {
      setThemeStyle(themeStyle);
    }

  }, []);


  const values = useMemo(
    () => ({
      themeStyle,
      setThemeStyle,
      mode,
      themConfig,
      changeTheme,
    }),
    [changeTheme, mode, themConfig, themeStyle]
  )

  return <ThemeStoreContext.Provider value={values}>{children}</ThemeStoreContext.Provider>

}

const useThemeStore = () => {
  const context = useContext(ThemeStoreContext);
  if (context === undefined) {
    throw new Error('useWallet hook must be used with a ThemeStoreContext component')
  }
  return context;
}

export {
  ThemeStoreProvider,
  useThemeStore,
}



